import React from 'react';
import classnames from 'classnames';
import { BaseLayout, Footer } from 'components';
import { isIOS } from 'utils';
import fetchJsonp from 'fetch-jsonp';
import { linkConfig, webCfg } from 'config';
import styles from './styles.module.scss';
import queryString from 'query-string';

class Download extends React.PureComponent {
	state = {
		androidUrl: 'https://downloads.zhongshunagriculture.cn/source/material/btcc.apk',
		iosUrl: `itms-services://?action=download-manifest&url=https://downloads.zhongshunagriculture.cn/source/material/download_btcc.plist`,
		loading: false,
		title: '立即下载',
		slogan: 'BTCC',
		platform: 'btcchy',
		detail: '/download/detail/btcc',
		download_hk: '/download/btcc/hk',
		step: false
	};
	constructor(props) {
		super(props);
		this.$download = React.createRef();
		this.$copy = React.createRef();
	}
	componentDidMount() {
		if (window.location.search) {
			const params = queryString.parse(window.location.search);
			if (
				params.platform &&
				params.title &&
				params.url &&
				Object.keys(linkConfig.download.ios).includes(params.platform)
			) {
				this.setState({
					androidUrl: params.url,
					slogan: params.title,
					platform: params.platform,
					detail: `${this.state.detail}${window.location.search}`
				});
			}
		}
		else {
			if (isIOS()) {
				this.setState({
					title: '本地下载'
				});
			}

			this.getUrl();
		}
	}
	getUrl = async () => {
		const res = await fetchJsonp(linkConfig.download.android);
		const ret = await res.json();
		if (ret.code === 'success') {
			this.setState({
				androidUrl: ret.data.url
			});
		}
	};
	hideDetail = () => {
		this.setState({
			step: false
		});
	};
	startDownload = () => {
		this.setState({
			loading: true,
			title: isIOS() ? '本地下载' : '获取中...',
			step: true
		});
		this.$download.current && this.$download.current.click();
		this.$timer = setTimeout(() => {
			this.setState({
				loading: false,
				title: isIOS() ? '本地下载' : '立即下载'
			});
			clearTimeout(this.$timer);
		}, 3000);
	};
	componentWillUnmount() {
		clearTimeout(this.$timer);
	}
	render() {
		const ua = window.navigator.userAgent.toLowerCase();
		const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
		const url = isIOS() ? this.state.iosUrl : this.state.androidUrl;
		const downloadUrl = isIOS() ? this.state.download_hk : this.state.androidUrl;
		return (
			<BaseLayout>
				<div className={classnames('full-screen', styles.download)}>
					{isWeixinBrowser ? (
						<div
							className={classnames(styles.shadow, {
								[styles.android]: !isIOS()
							})}
						/>
					) : null}
					<h2>实物交割 永续合约</h2>
					<p className={styles.summary}>中国第一家比特币交易平台</p>

					<div
						className={styles.animate}
						style={{
							backgroundImage: `url("/download/${this.state.platform}.png")`
						}}
					/>
					<h3>{this.state.slogan} App下载 </h3>
					<p className={styles.strength}>
						<span>安全</span>
						<span>稳定</span>
						<span>智能</span>
						<span>服务</span>
					</p>
					<div className={styles.download_wrapper}>
						<div className="flex-container flex-space-between">
							{isIOS() ? (
								<p className={styles.otherDownlod}>
									<a href={downloadUrl}>
										App Store
										<small>非大陆 Apple ID</small>
									</a>
								</p>
							) : null}
							{isIOS() ? (
								<a
									href={this.state.detail}
									className={classnames('flex-block', styles.download_btn, {
										[styles.loading]: this.state.loading
									})}
								>
									{this.state.title}
								</a>
							) : (
									<div
										onClick={this.startDownload}
										className={classnames('flex-block', styles.download_btn, {
											[styles.loading]: this.state.loading
										})}
									>
										{this.state.title}
									</div>
								)}
						</div>

						{/* {this.state.step && isIOS() ? (
              <div
                className={classnames(
                  styles.install,
                  "flex-container flex-y-end"
                )}
              >
                <div>
                  <div
                    onClick={this.hideDetail}
                    className={classnames("close", styles.close)}
                  />
                  <h3 className="text-center">安装信息</h3>
                  <p>
                    APP安装完成后，打开APP时，若出现弹窗提示“未受信任的企业级开发者”，需先对下方描述文件进行授信，授信方式可点击底部“快速信任”或查看
                    <a className={styles.link} href="/download/detail/btcc">
                      详细安装教程&gt;
                    </a>
                    <img
                      src={`http://btjiaoge.com/app/iosazjc/${
                        this.state.platform
                      }/t_0.png`}
                      alt=""
                    />
                  </p>
                  <p>
                    <a
                      href="https://downloads.zhongshunagriculture.cn/source/material/btcc.mobileprovision"
                      className={styles.confirm}
                    >
                      快速信任
                    </a>
                  </p>
                </div>
              </div>
            ) : null} */}
					</div>
					<a ref={this.$download} href={url} rel="noopener noreferrer" className={styles.download_link}>
						{isIOS() ? '本地下载' : '立即下载'}
					</a>

					<Footer title={webCfg[this.state.platform].copyright} />
				</div>
			</BaseLayout>
		);
	}
}

export default Download;
