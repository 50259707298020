import React from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { BaseLayout, Loading } from "components";
import { getNews } from "services";
import "react-perfect-scrollbar/dist/css/styles.css";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from 'utils';


class GuideList extends React.PureComponent {
  state = {
    askList: [],
    loading: true,
  };
  componentDidMount() {
    document.title = "存款指南";
    const id = process.env.BUILD_ENV === "uat" ? 270 : 469;
    this.getList(id);
  }

  getList = async id => {
    try {
      const res = await getNews(id, { limit: 200 });
      if (res.success) {
        this.setState({
          loading: false,
          askList: [...res.ch_msg],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 返回
  onBack = () => {
    window.history.back();
  }

  // 到在线客服
  goCustomer = () => {
    if (isNative) {
      nativeHref('customer');
    } else {
      const a = document.createElement('a');
      a.href = 'https://btcccfd.kf5.com/kchat/38726?from=%E5%9C%A8%E7%BA%BF%E6%94%AF%E6%8C%81&group=0#/chatbox';
      a.target = '_blank';
      a.click();
    }
  }


  // 跳转指定地址
  goHref = (link) => {
    const { history } = this.props;
    history.push(link);
  }
  render() {
    const { loading, askList, } = this.state;


    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
            <section className={styles.section}>
              <div className={styles.banner}>
                {isNative ? null : <div className={styles.back} onClick={this.onBack} />}
                <div className={styles.guide}>存款指南</div>
              </div>
              <div className={styles.askList}>
                {
                  askList.map((item, index) => (
                    <div key={index} className={styles.askList_item} onClick={() => this.goHref(`/detail_new/${item.id}`)}>
                      <div className={styles.askList_title}>{item.title}</div>
                      <div className={styles.askList_arrow} />
                    </div>
                  ))
                }
              </div>

              <footer className={styles.footer} onClick={this.goCustomer}>
                <div className={styles.icon_kefu} />
                <div>在线客服</div>
              </footer>
            </section>
          )}
      </BaseLayout>
    );
  }
}

export default withRouter(GuideList);
