import React from "react";
import styles from "./styles.module.scss";
class Detail extends React.Component {
  state = {
    title: "中国第一家比特币交易平台",
    platform: "btcc",
    url:
      "itms-services://?action=download-manifest&url=https://downloads.zhongshunagriculture.cn/source/material/download_btcc.plist"
  };
  render() {
    return (
      <div className={styles.local_detail}>
        <div className={styles.local_banner}>
          <h2>中国第一家比特币交易平台</h2>
          <p>
            <a href={this.state.url}>立即安装</a>
          </p>
          <p>点击安装后，请返回桌面查看</p>
        </div>
        <div className={styles.local_body}>
          <h3>安装完成后，请按以下操作</h3>
          <p>
            打开<b className={styles.symbol}>「设置」</b>
            <b className={styles.symbol}>「通用」</b>
            <b>「设备管理器」</b>
            找到BT App的描述文件，设置为信任。
          </p>
          <img
            alt={this.state.title}
            src={`http://btjiaoge.com/app/iosazjc/${
              this.state.platform
            }/t_1.jpg`}
          />
          <img
            alt={this.state.title}
            src={`http://btjiaoge.com/app/iosazjc/${
              this.state.platform
            }/t_2.jpg`}
          />
          <p>设置完成后，就可以使用“BT App”了</p>
        </div>
      </div>
    );
  }
}
export default Detail;
