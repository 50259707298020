import React from "react";
import { BaseLayout, Statement } from "components";
import { Carousel, WingBlank } from "antd-mobile";
import classnames from "classnames";
import styles from "./styles.module.scss";
class AboutUs extends React.PureComponent {
  componentDidMount() {
    document.title = "金牛财富（Taurus wealth）合约交易平台";
  }
  render() {
    return (
      <BaseLayout>
        <div className={styles.about_us}>
          <div
            className={classnames(
              styles.banner,
              "flex-container flex-center text-center"
            )}
          >
            金牛财富
            合约交易平台
          </div>
          <div className={classnames("plane", styles.about)}>
            <h3>关于金牛财富</h3>
            <p>
              金牛财富（Taurus
              wealth）是必拓环球集团旗下的差价合约交易平台，24小时为全球投资者提供外汇、贵金属、商品期货、股指、虚拟币等多元化环球金融产品在线交易，用户只需下载手机APP，即可不受时间及地域限制，领先一步掌握全球市场变化、把控盈利时机，做到「观全局，谋大事；察先机，与共赢」
            </p>
            <p>
              必拓环球集团于美国NFA(National Futures Association)
              注册并受美国法律监管，全面融合创新金融与区块链。金牛财富（Taurus
              wealth）背靠集团强大的资金实力及运维能力，组建超10年金融从业经验的权威团队，以专业的素质打造严谨的风控体系，为客户保驾护航，并提供24/7/365全时无忧的服务。
            </p>
            <p>
              一直以来，金牛财富（Taurus
              wealth）秉承“公平、创新、安全、专业”的理念，为客户提供一个「公平、透明」的交易环境向来是金牛财富所重视的，并以此作为我们的愿景。今天，我们仍在努力构筑这一愿景，推出更多优质的金融产品和服务，敢于打破行业框架，推动行业一次次的创新和转型。次次的创新和转型。
            </p>
          </div>
          <div className={classnames("plane", styles.strength)}>
            <h3>我们的核心价值观</h3>
            <div className="flex-container flex-space-between flex-wrap">
              <div className={styles.strength_item}>
                <i className={styles.icon_1} />
                <p>安全</p>
              </div>
              <div className={styles.strength_item}>
                <i className={styles.icon_2} />
                <p>稳定</p>
              </div>
              <div className={styles.strength_item}>
                <i className={styles.icon_3} />
                <p>智能</p>
              </div>
              <div className={styles.strength_item}>
                <i className={styles.icon_4} />
                <p>服务</p>
              </div>
            </div>
          </div>
          <div className="plane">
            <h3>六大优势，与您共赢</h3>
            <WingBlank>
              <Carousel className="my-carousel" autoplay infinite>
                <div className={styles.slide}>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_1} />
                    <p>多重加密技术及10年金融安全团队，全面保护资金安全</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_2} />
                    <p>法币充值提取，快速交易</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_3} />
                    <p>提供保证金交易， 支持模拟体验交易</p>
                  </div>
                </div>
                <div className={styles.slide}>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_4} />
                    <p>智能止盈止损设置</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_5} />
                    <p>每笔行情数据公开透明</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_6} />
                    <p>全天候的专业服务全时无忧</p>
                  </div>
                </div>
              </Carousel>
            </WingBlank>
          </div>
          <div
            className={classnames(
              "flex-container flex-y-center",
              styles.slogan
            )}
          >
            <div>
              <h3>全心服务，全意呈现</h3>
              <p>提供专业客服服务</p>
            </div>
          </div>
        </div>
        <Statement title="金牛财富（Taurus wealth）" />
      </BaseLayout>
    );
  }
}
export default AboutUs;
