import React from "react";
import { withRouter, Link } from "react-router-dom";
import { BaseLayout, Loading } from "components";
import { getNews, getCategory } from "services";
import styles from "./styles.module.scss";

class Category extends React.PureComponent {
  state = {
    loading: true,
    hots: [],
    cat: [],
  };
  getList = async (id) => {
    try {
      const [res, cat] = await Promise.all([
        getNews(id, { isHot: true, limit: 15 }),
        getCategory(id),
      ]);
      const params = {
        loading: false,
      };
      if (res.code === 200 && Array.isArray(res.ch_msg)) {
        params.hots = [...res.ch_msg];
      }
      if (cat.code === 200 && Array.isArray(cat.ch_msg)) {
        params.cat = cat.ch_msg.slice(0, 3);
      }
      this.setState(params);
    } catch (error) {}
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getList(id);
    }
  }
  render() {
    const { loading, hots, cat } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.content}>
            {cat.length > 0 && (
              <div className={styles.intro}>
                {cat.map((item) => (
                  <div
                    class="item"
                    key={item.id}
                    style={{ backgroundImage: `url(${item.image})` }}
                  >
                    <Link to={`/cat/${item.id}`}>
                      <h3>{item.name}</h3>
                      
                    </Link>
                  </div>
                ))}
              </div>
            )}
            <h3 className={styles.title}>常见问题</h3>
            {hots.length > 0 ? (
              <ul className="list-unstyled">
                {hots.map((item) => (
                  <li key={item.id}>
                    <Link to={`/detail/${item.id}`}>
                      <span>{item.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        )}
      </BaseLayout>
    );
  }
}
export default withRouter(Category);
