import React from "react";
import { Toast } from "antd-mobile";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./styles.module.scss";
import { linkConfig } from "config";
import { getAccount } from "services";
import queryString from 'query-string';
class Detail extends React.Component {
  state = {
    platform: "btccgl",
    title: "BT Global",
    account: []
  };
  copy = () => {
    Toast.success("复制成功！", 1);
  };
  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        params.title &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          platform: params.platform,
          title: params.title
        });
      }
    }
    this.getList();
  }
  getList = async () => {
    try {
      const res = await getAccount();
      this.setState({
        account: [...res.list]
      });
    } catch (error) {}
  };
  render() {
    return (
      <div className={styles.step_detail}>
        <h3 className="page-title text-center">如何切换苹果ID</h3>
        <h5>
          <i className={styles.list_icon}>1</i> 点击App Store首页右上角头像
        </h5>
        <img src="/download/a_1.png" alt="点击App Store首页右上角头像" />
        <h5>
          <i className={styles.list_icon}>2</i> 点击退出登录，重新输入Apple
          ID及密码
        </h5>
        <img
          src="/download/a_2.png"
          alt="点击退出登录，重新输入Apple ID及密码"
        />
        <h5>3. 以下香港ID供参考使用</h5>
        <ul className={styles.account_list}>
          {this.state.account.map(item => (
            <li key={item.name}>
              <p>
                账号：{item.name}
                <CopyToClipboard text={item.name}>
                  <span onClick={this.copy}>复制</span>
                </CopyToClipboard>
              </p>
              <p>
                密码：{item.pwd}
                <CopyToClipboard text={item.pwd}>
                  <span onClick={this.copy}>复制</span>
                </CopyToClipboard>
              </p>
            </li>
          ))}
        </ul>
        <div className={styles.rules}>
          <p>
            为了防止您的手机信息泄露，请务必不要使用
            上方AppleID登录iCloud。使用参考AppleID下
            载过APP后，请您及时退出该账号。
          </p>
          <p>
            使用过程中有任何问题，可联系
            <a
              className={styles.link}
              href={linkConfig.online}
              rel="noopener noreferrer"
              target="_blank"
            >
              在线客服
            </a>
          </p>
        </div>

        <p className={styles.fixed_bottom}>
          <a
            href={linkConfig.download.ios[this.state.platform]}
            className={styles.confirm}
          >
            <i />
            我已了解，立即下载
          </a>
        </p>
      </div>
    );
  }
}
export default Detail;
