import React from "react";
import classnames from "classnames";
import { BaseLayout, Footer } from "components";
import { isIOS } from "utils";
import { linkConfig, webCfg } from "config";
import styles from "./styles.module.scss";
import queryString from 'query-string';
class Download extends React.PureComponent {
  state = {
    androidUrl: linkConfig.download.androidUrl["jn"],
    iosUrl: linkConfig.download.ios["jn"],
    loading: false,
    title: "立即下载",
    slogan: "金牛财富APP下载",
    platform: "jn",
    detail: "/download/detail/jn",
  };
  constructor(props) {
    super(props);
    this.$download = React.createRef();
    this.$copy = React.createRef();
  }
  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        params.title &&
        params.url &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          androidUrl: params.url,
          slogan: params.title,
          platform: params.platform
        });
      }
    }
  }
  // startDownload = () => {
  //   this.setState({
  //     loading: true,
  //     title: "获取中...",
  //     step: isIOS() ? true : false
  //   });
  //   this.$download.current && this.$download.current.click();
  //   this.$timer = setTimeout(() => {
  //     this.setState({
  //       loading: false,
  //       title: "立即下载"
  //     });
  //     clearTimeout(this.$timer);
  //   }, 3000);
  // };
  componentWillUnmount() {
    clearTimeout(this.$timer);
  }
  render() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);

    const url = isIOS() ? this.state.detail : this.state.androidUrl;
    // const url = isIOS()
    //   ? `itms-services://?action=download-manifest&url=${
    //       linkConfig.download.ios[this.state.platform]
    //     }`
    //   : this.state.androidUrl;
    return (
      <BaseLayout>
        <div
          className={classnames("full-screen", styles.download, {
            [styles.fixed_scroll]: this.state.step || this.state.detail
          })}
        >
          {isWeixinBrowser ? (
            <div
              className={classnames(styles.shadow, {
                [styles.android]: !isIOS()
              })}
            />
          ) : null}
          <h2>合约交易 触手可及</h2>
          <p className={styles.summary}>全球著名的差价合约交易平台</p>

          <div
            className={styles.ic_logo}
            style={{
              backgroundImage: `url("/download/${this.state.platform}.png")`
            }}
          />
          <h3>{this.state.slogan}</h3>
          <p className={styles.strength}>
            <span>安全</span>
            <span>稳定</span>
            <span>智能</span>
            <span>服务</span>
          </p>
          <div className={styles.download_wrapper}>
            <a
              // onClick={this.startDownload}
              href={url}
              className={classnames(styles.download_btn, {
                [styles.loading]: this.state.loading
              })}
            >
              <span />
              {this.state.title}
              <span className="label"></span>
            </a>
          </div>
          {/* <a
            download
            ref={this.$download}
            href={url}
            rel="noopener noreferrer"
            className={styles.download_link}
          >
            立即下载
            <span className="label"></span>
          </a> */}

          <Footer title={webCfg[this.state.platform].copyright} />
        </div>
      </BaseLayout>
    );
  }
}

export default Download;
