import React from "react";
import { BaseLayout, Statement } from "components";
import { Carousel, WingBlank } from "antd-mobile";
import classnames from "classnames";
import styles from "./styles.module.scss";
class AboutUs extends React.PureComponent {
  componentDidMount() {
    document.title = "关于必拓环球（BT Global）";
  }
  render() {
    return (
      <BaseLayout>
        <div className={styles.about_us}>
          <div
            className={classnames(
              styles.banner,
              "flex-container flex-center text-center"
            )}
          >
            必拓环球（BT Global）
            <br /> 合约交易平台
          </div>
          <div className={classnames("plane", styles.about)}>
            <h3>关于必拓环球（BT Global）</h3>
            <p>
              必拓环球（BT
              Global）是BTCC集团旗下的差价合约交易平台，于美国NFA(National
              Futures Association) 注册 (NFA ID:
              0520541)并受美国法律监管，24小时为全球投资者提供外汇、贵金属、商品期货、股指、虚拟币等多元化环球金融产品在线交易。
            </p>
            <p>
              BTCC集团起源于比特币中国（2015年9月15日，比特币中国BTC
              China正式更名为BTCC)。2018年1月29日，BTCC集团正式成立，全面融合创新金融与区块链。目前，BTCC集团涉及金融生态各个领域，包括数字资产矿池、Mobi钱包、现货交易、必拓环球（BTCC
              Global）差价合约交易平台。
            </p>
            <p>
              必拓环球（BT
              Global）背靠集团强大的资金实力及运维能力，组建超10年金融从业经验的权威团队，以专业的素质打造严谨的风控体系，为客户保驾护航，并提供24/7/365全时无忧的服务
            </p>
            <p>合约交易，触手可及</p>
            <p>
              一直以来，必拓环球（BT
              Global）秉承“公平、创新、安全、专业”的理念，从客户利益出发，提供充满吸引力的杠杆率、业内超低的点差和及时稳定的出入金；用每一次创新、严谨、认真，满足客户的投资需求，同进步，与共赢，是您值得拥有的差价合约交易平台。
            </p>
          </div>
          <div className={classnames("plane", styles.strength)}>
            <h3>我们的核心价值观</h3>
            <div className="flex-container flex-space-between flex-wrap">
              <div className={styles.strength_item}>
                <i className={styles.icon_1} />
                <p>安全</p>
              </div>
              <div className={styles.strength_item}>
                <i className={styles.icon_2} />
                <p>稳定</p>
              </div>
              <div className={styles.strength_item}>
                <i className={styles.icon_3} />
                <p>智能</p>
              </div>
              <div className={styles.strength_item}>
                <i className={styles.icon_4} />
                <p>服务</p>
              </div>
            </div>
          </div>
          <div className="plane">
            <h3>六大优势，与您共赢</h3>
            <WingBlank>
              <Carousel className="my-carousel" autoplay infinite>
                <div className={styles.slide}>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_1} />
                    <p>多重加密技术及10年金融安全团队，全面保护资金安全</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_2} />
                    <p>法币充值提取，快速交易</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_3} />
                    <p>提供保证金交易， 支持模拟体验交易</p>
                  </div>
                </div>
                <div className={styles.slide}>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_4} />
                    <p>智能止盈止损设置</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_5} />
                    <p>每笔行情数据公开透明</p>
                  </div>
                  <div className="flex-container flex-y-center">
                    <i className={styles.i_6} />
                    <p>全天候的专业服务全时无忧</p>
                  </div>
                </div>
              </Carousel>
            </WingBlank>
          </div>
          <div
            className={classnames(
              "flex-container flex-y-center",
              styles.slogan
            )}
          >
            <div>
              <h3>全心服务，全意呈现</h3>
              <p>提供专业客服服务</p>
            </div>
          </div>
        </div>
        <Statement />
      </BaseLayout>
    );
  }
}
export default AboutUs;
