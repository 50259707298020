import React from "react";
import { getMsgDetail, addH5Event } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from "utils";
import queryString from "query-string";

const _params = queryString.parse(window.location.search);

class GuideDetail extends React.PureComponent {
  state = {
    loading: true,
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    document.title = "消息详情";

    if (!params.id || !Number.isInteger(parseInt(params.id))) {
      history.goBack();
    }
    this.getDetail();

    setTimeout(() => {
      const goNativeEle = document.getElementsByClassName("goNative");
      const { symbolId } = this.state;
      if (goNativeEle.length >= 1) {
        Array.from(goNativeEle).map((item) => {
          item.onclick = function() {
            var type = this.getAttribute("data-type");
            if ((type === "toQutation" || type === "toChat") && symbolId) {
              nativeHref(type || "", { chartId: symbolId });
            } else {
              nativeHref(type || "");
            }
          };
        });
      }

      // 改动文章模版
      const _tables = document.getElementsByTagName("table");
      [..._tables].forEach((element) => {
        element.style.tableLayout = "auto";
      });
    }, 500);
  }
  getDetail = async () => {
    const { match } = this.props;
    const { params } = match;
    const customerNo = _params.customerNo || "";
    const res = await getMsgDetail(params.id, customerNo);
    if (res.code === "0") {
      this.setState({
        ...res.data,
      });

      // 广告统计
      if (_params.advertisingId) {
        addH5Event(customerNo, _params.advertisingId);
      }
    }

    this.setState({
      loading: false,
    });
  };

  // 返回
  onBack = () => {
    window.history.back();
  };

  getInfoFromType(type) {
    const { clickUrl = "" } = this.state;
    // 跳转H5地址
    const h5TypeConfig = {
      startLearn: "开始学习",
      toInvitation: "去邀请",
      activityDetail: "查看活动详情",
    };

    // 和app 交互
    const appTypeConfig = {
      deposit: "去存款",
      toTrade: "去交易",
      customer: "咨询客服",
      accountPrivilege: "查看账户权益",
      information: "去认证",
      toQutation: "去查看", // 行情异动推送的消息。
      toCouponList: "去使用", // 优惠券列表
      toChat: "去查看", // 聊天室推送的消息。
      toTransfer: "去查看", // 跳錢包
      toPersonal: "去查看", // 跳個人頁
      toBonus: "去查看", // 跳转到赠金明细
      toCoupon: "去查看", // 跳转到礼券
      toNewbieTasks: "开始新手任务", // 跳新手任务頁
    };
    if (h5TypeConfig[type]) {
      const url = queryString.stringifyUrl({
        url: clickUrl,
        query: { ..._params },
      });

      return `<p><a style="color: #2064DA;" href=${url}>${h5TypeConfig[type] ||
        ""} &gt;&gt;</a></p>`;
    } else if (appTypeConfig[type] && isNative) {
      return `<p><a class="goNative" style="color: #2064DA;" data-type=${type}>${appTypeConfig[
        type
      ] || ""} &gt;&gt;</a></p>`;
    } else {
      return "";
    }
  }

  render() {
    const { loading, title, content, clickType = "" } = this.state;

    let _content = content;

    // 拼接地址
    if (clickType.includes(",")) {
      const arr = clickType.split(",");
      arr.forEach((item) => {
        const linkList = this.getInfoFromType(item);
        _content += linkList;
      });
    } else if (clickType) {
      const linkList = this.getInfoFromType(clickType);
      _content += linkList;
    }

    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <section className={styles.detail_new}>
            {isNative ? null : (
              <header className={styles.header}>
                <div className={styles.back} onClick={this.onBack} />
                <div className={styles.title}>消息详情</div>
              </header>
            )}
            {title && (
              <div className={styles.content}>
                <div className={styles.detail}>
                  <div className={styles.detail_header}>
                    <span className={styles.title}>{title}</span>
                  </div>
                  <div
                    className={styles.detail_content}
                    dangerouslySetInnerHTML={{ __html: _content }}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
